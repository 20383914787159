var preload_background = new Image();
preload_background.onload = function() {
	showPage( this );
}

var showPage = function( img ) {
	if ( null != img ) {
		$('#back').css('background-image', 'url(' + img.src + ')');
	} else {
		$('#back').css('background-image', '');
	}
	$('#back').animate({
		'opacity': 0.6
	}, 500, function() {
		$('body').removeClass('loading');
	});
}

/* ===== History.js ===== */
// Establish Variables
var State = History.getState();
	//$log = $('#log');

// Log Initial State
// History.log('initial:', State.data, State.title, State.url);

// Bind to State Change
History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
	// Log the State
	State = History.getState(); // Note: We are using History.getState() instead of event.state
	// History.log('statechange:', State.data, State.title, State.url);

	$('#menu').removeClass('open');
	$('body').removeClass().addClass('loading');

	$.ajax({
		method: "GET",
		url: State.url,
		dataType: 'json',
		data: { /*name: "John", location: "Boston"*/ }
	})
	.done(function( data, textStatus, jqXHR ) {
		// console.log( textStatus ); // "success"
		History.replaceState({state:State.data.page,rand:State.data.rand}, data.title, State.url); // should not trigger statechange-loop
		$('meta[name="description"]').attr('content', data.description );
		$('meta[name="keywords"]').attr('content', data.keywords );
		$('body').addClass( data.bodyclass );
		$('#back').animate({
			'opacity': 0
		}, 500, function(){
			document.title = data.title;
			$('#page').html( data.content ).find('a').on('click', doNavigate);
			if ('' != data.background_picture) {
				preload_background.src = data.background_picture;
			} else {
				showPage( null );
			}
		});
	})
	.fail(function( jqXHR, textStatus, errorThrown ) {
		console.log( textStatus ); // "error"
		//History.pushState({state:page,rand:Math.random()}, page_title, page_url);
		$('#page').html( '<div class="block-content blockstyle-transparent col3-12"></div><div class="block-content blockstyle-brown col6-12"><h2>Oeps...</h2>Pagina kon niet gevonden worden.</div>' )
		$('body').removeClass('loading');
	});

});


var doNavigate = function(e){
	var $a = $( this );
	var page_url = $a.attr('href');
	var page = $a.data('page');
	var page_title = $a.text();

	if ( location.hostname === this.hostname || !this.hostname.length ) {
		e.preventDefault();
		History.pushState({state:page,rand:Math.random()}, null, page_url);
	}

	return true;
};


function positionFooter() {
	var $f = $('#footer');
	if ( $f && $f.length > 0 ) {
		$f.css('margin-top', '0px');
		// var $o = $('#page div.block-content').last();
		// var y = parseInt( ( $o.offset() ).top );
		// var h = parseInt( $o.outerHeight() );
		// var fh = parseInt( $f.outerHeight() );
		var hh = $('html').outerHeight();
		var wh = $( window ).outerHeight();

		if ( wh > hh ) {
			$f.css('margin-top', (wh-hh) + 'px');
		}
	}
}

$( document ).ready(function(){

	//$('a[data-page], #page a').on('click', doNavigate);

	$('#menu-wrapper')
		.find('ul a')
			.on('click', function(e) {
				var $a = $( this );
				$('#menu-wrapper .menu-item').removeClass('on semi');
				//$a.parent().siblings('li.on').removeClass('on');
				$a.parent().addClass('on').parents('.menu-item').addClass('semi');
			})
		.end()
		.find('> a')
			.on('click', function(e){
				e.preventDefault();
				$('#menu').toggleClass('open');
			})
		.end();

	/*
	History.pushState({state:1,rand:Math.random()}, "State 1", "?state=1"); // logs {state:1,rand:"some random value"}, "State 1", "?state=1"
	History.replaceState({state:3,rand:Math.random()}, "State 3", "?state=3"); // logs {state:3,rand:"some random value"}, "State 3", "?state=3"
	History.back();
	History.go(2);
	*/

	$( window ).on('resize', function() {
		positionFooter();
	});
	setTimeout(function(){
		positionFooter();
		$('#footer').animate({opacity: 1}, 150, 'swing');
	}, 1000);

});